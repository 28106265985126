import { RouteConfig } from 'vue-router'

import AppbarLogoDense from '@/components/appbar/logo-dense.vue'
import AccuracyAppbar from '@/modules/accuracy/components/appbar/index.vue'
import CommentsAppbar from '@/modules/comment/components/appbar/index.vue'
import EditorAppbarHelp from '@/modules/editor/components/appbar/help.vue'
import EditorAppbarInfo from '@/modules/editor/components/appbar/info.vue'
import OrganizationUpgrade from '@/modules/organization/components/appbar/upgrade.vue'
import RecommendationsAppbar from '@/modules/recommendation/components/appbar/index.vue'
import AppbarShare from '@/modules/share/components/appbar/share.vue'
import UserAppbarAvatars from '@/modules/user/components/appbar/avatars.vue'

const meta = {
  background: '#323435',
  disableScroll: true,
  hasAppbar: true,
  requiresAuth: true,
  requiresSocket: true
}

const appbar = {
  'appbar-left-1': AppbarLogoDense,
  'appbar-left-2': EditorAppbarInfo,
  'appbar-right-1': AppbarShare,
  'appbar-right-2': UserAppbarAvatars,
  'appbar-right-3': EditorAppbarHelp,
  'appbar-right-4': AccuracyAppbar,
  'appbar-right-5': RecommendationsAppbar,
  'appbar-right-6': CommentsAppbar,
  'appbar-right-7': OrganizationUpgrade
}

export default [
  {
    components: {
      default: () => import(/* webpackChunkName: "version-viewer" */ './views/viewer.vue'),
      ...appbar
    },
    meta,
    name: 'version-diagram',
    path: '/landscapes/:landscapeId/versions/:versionId/diagrams/editor'
  },

  {
    path: '/projects/:landscapeId/versions/:versionId/model',
    redirect: to => ({
      name: 'model-objects',
      params: to.params,
      query: to.query
    })
  },
  {
    path: '/projects/:landscapeId/versions/:versionId/diagrams',
    redirect: to => ({
      name: 'version-diagram',
      params: to.params,
      query: to.query
    })
  },
  {
    path: '/projects/:landscapeId/versions/:versionId/views',
    redirect: to => ({
      name: 'version-diagram',
      params: to.params,
      query: to.query
    })
  }
] as RouteConfig[]
