import { SocketClientVersionsSubscribeBody, Version, VersionPartial, VersionRequired, VersionRevert, VersionRevertPartial, VersionRevertRequired, VersionRevertsService, VersionsService } from '@icepanel/platform-api-client'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import Status from '@/helpers/status'
import landscapeAnalyticsGroup from '@/modules/landscape/helpers/analytics-group'
import { ServerEvents, Socket } from '@/plugins/socket'

const sortVersions = (versions: Version[]) => versions.sort((a, b) => {
  const aLatest = a.tags.includes('latest')
  const bLatest = b.tags.includes('latest')
  if (aLatest === bLatest) {
    return a.createdAt > b.createdAt ? -1 : 1
  } else if (aLatest && !bLatest) {
    return -1
  } else if (!aLatest && bLatest) {
    return 1
  } else {
    return 0
  }
})

export interface IVersionModule {
  travelling: boolean
  versions: Version[]
  versionReverts: VersionRevert[]

  versionsSubscriptionStatus: Status<{ subscriptionId: string, landscapeId: string, unsubscribe: () => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, unsubscribe: () => void }>

  versionsListStatus: Status<{ landscapeId: string }, { landscapeId: string }>
  versionRevertsListStatus: Status<{ landscapeId: string }, { landscapeId: string }>
  versionRevertCreateStatus: Status<{ landscapeId: string }, { landscapeId: string }>
}

const name = 'version'

@Module({
  name,
  namespaced: true
})
export class VersionModule extends VuexModule implements IVersionModule {
  static namespace = name

  travelling = false

  versions: Version[] = []
  versionReverts: VersionRevert[] = []

  versionsSubscriptionStatus = new Status<{ subscriptionId: string, landscapeId: string, unsubscribe:() => void, reconnect: boolean }, { subscriptionId: string, landscapeId: string, unsubscribe: () => void }>()

  versionsListStatus = new Status<{ landscapeId: string }, { landscapeId: string }>()
  versionRevertsListStatus = new Status<{ landscapeId: string }, { landscapeId: string }>()
  versionRevertCreateStatus = new Status<{ landscapeId: string }, { landscapeId: string }>()

  get socket (): Socket {
    return this.context.rootState.socket.socket
  }

  @Mutation
  setTravelling (travelling: boolean) {
    this.travelling = travelling
  }

  @Mutation
  resetVersions () {
    this.versions = []
  }

  @Mutation
  setVersions (versions: Version[]) {
    this.versions = versions
    sortVersions(versions)
  }

  @Mutation
  setVersion (version: Version) {
    this.versions = [
      ...this.versions.filter(o => o.id !== version.id),
      version
    ]
    sortVersions(this.versions)
  }

  @Mutation
  removeVersion (versionId: string) {
    this.versions = this.versions.filter(o => o.id !== versionId)
  }

  @Mutation
  setVersionReverts (versionReverts: VersionRevert[]) {
    this.versionReverts = versionReverts
  }

  @Mutation
  setVersionRevert (versionRevert: VersionRevert) {
    this.versionReverts = [
      ...this.versionReverts?.filter(o => o.id !== versionRevert.id) || [],
      versionRevert
    ]
  }

  @Mutation
  setVersionsListStatus (...params: Parameters<typeof this.versionsListStatus.set>) {
    this.versionsListStatus.set(...params)
  }

  @Mutation
  setVersionRevertsListStatus (...params: Parameters<typeof this.versionRevertsListStatus.set>) {
    this.versionRevertsListStatus.set(...params)
  }

  @Mutation
  setVersionRevertCreateStatus (...params: Parameters<typeof this.versionRevertCreateStatus.set>) {
    this.versionRevertCreateStatus.set(...params)
  }

  @Mutation
  setVersionsSubscriptionStatus (...params: Parameters<typeof this.versionsSubscriptionStatus.set>) {
    this.versionsSubscriptionStatus.set(...params)
  }

  @Mutation
  versionsUnsubscribe () {
    this.versionsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.versionsSubscriptionStatus.successInfo?.unsubscribe?.()
    this.versionsSubscriptionStatus.set(Status.idle())
  }

  @Action({ rawError: true })
  async versionsSubscribe (body: SocketClientVersionsSubscribeBody & { reconnect: boolean }) {
    this.versionsSubscriptionStatus.loadingInfo?.unsubscribe?.()
    this.versionsSubscriptionStatus.successInfo?.unsubscribe?.()

    this.context.commit('setVersionsSubscriptionStatus', Status.loading({
      landscapeId: body.landscapeId,
      reconnect: body.reconnect
    }))

    const initialValue: Version[] = []

    await new Promise<void>((resolve, reject) => {
      this.socket.emit('versions-subscribe', {
        landscapeId: body.landscapeId
      }, (err, reply) => {
        if (reply) {
          const versionInitialValueListener: ServerEvents['version-initial-value'] = ({ versions, finalValue, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              initialValue.push(...versions)

              if (finalValue) {
                this.context.commit('setVersions', initialValue)

                this.context.commit('setVersionsSubscriptionStatus', Status.success({
                  landscapeId: body.landscapeId,
                  subscriptionId: reply.subscriptionId,
                  unsubscribe
                }))

                this.context.dispatch('socket/checkSocket', undefined, { root: true })
              }
            }
          }
          const versionAddedListener: ServerEvents['version-added'] = ({ version, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setVersion', version)
            }
          }
          const versionModifiedListener: ServerEvents['version-modified'] = ({ version, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('setVersion', version)
            }
          }
          const versionRemovedListener: ServerEvents['version-removed'] = ({ version, subscriptionId }) => {
            if (reply.subscriptionId === subscriptionId) {
              this.context.commit('removeVersion', version)
            }
          }

          const socketId = this.socket.id

          const unsubscribe = () => {
            this.socket.off('version-initial-value', versionInitialValueListener)
            this.socket.off('version-added', versionAddedListener)
            this.socket.off('version-modified', versionModifiedListener)
            this.socket.off('version-removed', versionRemovedListener)

            if (this.socket.id === socketId && this.socket.connected) {
              this.socket.emit('versions-unsubscribe', {
                subscriptionId: reply.subscriptionId
              }, err => {
                if (err) {
                  console.error('versions unsubscribe error', err)
                }
              })
            }
          }

          this.socket.on('version-initial-value', versionInitialValueListener)
          this.socket.on('version-added', versionAddedListener)
          this.socket.on('version-modified', versionModifiedListener)
          this.socket.on('version-removed', versionRemovedListener)

          this.context.commit('setVersionsSubscriptionStatus', Status.loading({
            landscapeId: body.landscapeId,
            reconnect: body.reconnect,
            subscriptionId: reply.subscriptionId,
            unsubscribe
          }))

          resolve()
        } else {
          const error = err || 'Version subscription not provided'
          this.context.commit('setVersionsSubscriptionStatus', Status.error(error))
          reject(new Error(error))
        }
      })
    })
  }

  @Action({ rawError: true })
  async versionsList (landscapeId: string) {
    try {
      this.context.commit('setVersionsListStatus', Status.loading({
        landscapeId
      }))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { versions } = await VersionsService.versionsList(authorization, landscapeId)
      this.context.commit('setVersions', versions)

      landscapeAnalyticsGroup.set(landscapeId, {
        versionCount: versions.filter(o => !o.tags.includes('latest')).length
      })

      this.context.commit('setVersionsListStatus', Status.success({
        landscapeId
      }))

      return versions
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setVersionsListStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async versionFind ({ landscapeId, versionId }: { landscapeId: string, versionId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { version } = await VersionsService.versionFind(authorization, landscapeId, versionId)
    this.context.commit('setVersion', version)
    return version
  }

  @Action({ rawError: true })
  async versionCreate ({ landscapeId, props }: { landscapeId: string, props: VersionRequired }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { version } = await VersionsService.versionCreate(authorization, landscapeId, props)
    this.context.commit('setVersion', version)
    return version
  }

  @Action({ rawError: true })
  async versionUpdate ({ landscapeId, props, versionId }: { landscapeId: string, props: VersionPartial, versionId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { version } = await VersionsService.versionUpdate(authorization, landscapeId, versionId, props)
    this.context.commit('setVersion', version)
    return version
  }

  @Action({ rawError: true })
  async versionRevertsList (landscapeId: string) {
    try {
      this.context.commit('setVersionRevertsListStatus', Status.loading({
        landscapeId
      }))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { versionReverts } = await VersionRevertsService.versionRevertsList(authorization, landscapeId)
      this.context.commit('setVersionReverts', versionReverts)

      landscapeAnalyticsGroup.set(landscapeId, {
        versionRevertCount: versionReverts.length
      })

      this.context.commit('setVersionRevertsListStatus', Status.success({
        landscapeId
      }))

      return versionReverts
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setVersionRevertsListStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async versionRevertCreate ({ landscapeId, props }: { landscapeId: string, props: VersionRevertRequired }) {
    try {
      this.context.commit('setVersionRevertCreateStatus', Status.loading({
        landscapeId
      }))

      const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
      const { versionRevert } = await VersionRevertsService.versionRevertCreate(authorization, landscapeId, props)

      this.context.commit('setVersionRevert', versionRevert)

      const latestVersion = this.versions?.find(o => o.landscapeId === landscapeId && o.tags.includes('latest'))
      if (latestVersion) {
        await this.context.dispatch('versionFind', {
          landscapeId,
          versionId: latestVersion?.id
        })
      }

      this.context.commit('setVersionRevertCreateStatus', Status.success({
        landscapeId
      }))

      return versionRevert
    } catch (err: any) {
      const message = err.body?.message || err.message
      this.context.commit('setVersionRevertCreateStatus', Status.error(message))
      this.context.commit('alert/pushAlert', { color: 'error', message }, { root: true })
      throw err
    }
  }

  @Action({ rawError: true })
  async versionRevertUpdate ({ landscapeId, versionRevertId, props }: { landscapeId: string, versionRevertId: string, props: VersionRevertPartial }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    const { versionRevert } = await VersionRevertsService.versionRevertUpdate(authorization, landscapeId, versionRevertId, props)
    this.context.commit('setVersionRevert', versionRevert)
    return versionRevert
  }

  @Action({ rawError: true })
  async versionExportJson ({ landscapeId, versionId }: { landscapeId: string, versionId: string }) {
    const authorization = await this.context.dispatch('user/getAuthorizationHeader', undefined, { root: true })
    return VersionsService.versionExportJson(authorization, landscapeId, versionId)
  }
}
